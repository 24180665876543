import { createSlice } from '@reduxjs/toolkit';
import { OrderDirection } from 'utils/types';
import type { RootState } from 'app/store';
import { buildBaseAndFilters, generateSearchFilter } from 'utils/serverFilters';
import { UserRole } from 'utils/types/users';
import { UserManagementFilterType } from 'pages/settings/userManagement/UserManagement.consts';

export interface UserManagementFilters {
  [UserManagementFilterType.Company]: string[];
  [UserManagementFilterType.Role]: UserRole[];
  [UserManagementFilterType.Notifications]: string[];
  [UserManagementFilterType.SearchQuery]: string;
}

export interface UserManagement {
  filters: UserManagementFilters;
  order: {
    [key: string]: OrderDirection;
  };
}

export const initialState: UserManagement = {
  filters: {
    [UserManagementFilterType.Company]: [],
    [UserManagementFilterType.Role]: [],
    [UserManagementFilterType.Notifications]: [],
    [UserManagementFilterType.SearchQuery]: '',
  },
  order: {
    fullName: OrderDirection.ASC,
  },
};

export const getFilters = (markedId: number, initialFilters: UserManagementFilters): any => {
  const UserManagementServerFiltersRecord = {
    [UserManagementFilterType.Company]: { serverFilter: 'company', operation: 'in' },
    [UserManagementFilterType.Role]: { serverFilter: 'UserMarket_role', operation: 'in' },
    [UserManagementFilterType.Notifications]: { serverFilter: 'UserMarket_notifications', operation: 'in_array' },
  };

  const filters = buildBaseAndFilters(UserManagementServerFiltersRecord, initialFilters);
  filters.AND.push({ active: { is: 'true' } });
  filters.AND.push({ UserMarket_marketId: { is: markedId } });
  filters.AND.push({ UserMarket_isActive: { is: 'true' } });
  filters.AND.push({ UserMarket_role: { not : UserRole.SysAdmin } });

  if (initialFilters[UserManagementFilterType.SearchQuery]?.length) {
    const searchByFields = ['firstName', 'lastName', 'email'];
    filters.AND.push(generateSearchFilter(searchByFields, [], initialFilters[UserManagementFilterType.SearchQuery]));
  }

  return filters;
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filters = {
        ...state.filters,
        [action.payload.filter]: action.payload.value,
      };
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
});

export const userManagement = (state: RootState) => state.userManagement;
export const { setFilter, setFilters, setOrder } = userManagementSlice.actions;

export default userManagementSlice.reducer;
