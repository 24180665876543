import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { ButtonDropdown } from 'components/shared/button/buttonDropdown';
import { StyledWithThemeProps } from 'utils/types';

export const MarketDropdownContainer = styled.div`
display: flex;
height: 100%;
padding-right: ${typography.unit}px;
font-weight: 300;
align-items: center;
`
export const MarketDropDownItem = styled(ButtonDropdown) <StyledWithThemeProps>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  button {
    padding: 0 ${typography.unit * 6}px;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: 300;
    text-align: center;
    height: 100%;
    svg {
      width: ${typography.unit * 2}px;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.tabStrip.backgroundHover};
    }
  }
  ul {
    top: calc(100% + ${typography.unit * 2}px);
    border: 1px solid ${({ theme }) => theme.colors.global.border};
    box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
    min-width: 224px;
  }
  li {
    padding: ${typography.unit * 4}px ${typography.unit * 4}px;
    height: auto;
    font-weight: 300;
    &:hover, 
    &:active,
    &:focus {
      background-color: ${({ theme }) => theme.colors.tabStrip.backgroundHover};
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const StyledLabel = styled.span`
  padding: 0 ${typography.unit * 6}px;
`

export const StyledButtonLabel = styled.span`
  margin-right: 18px;
`