import React, { useEffect, useState } from 'react';
import { StyledHeader, StyledLoader } from 'pages/shared/shared.style';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import TextPageTitle from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { FormMode } from 'utils/types';
import { Actions, StyledDnDItems } from './DealPriorityConfiguration.style';
import DragAndDropList from 'components/shared/dragAndDrop/DragAndDrop';
import { closestCorners, DndContext } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import PriorityTitle from './components/priorityTitle/PriorityTitle';
import { users } from 'app/slices/users';
import { useSelector } from 'react-redux';
import { UserRole } from 'utils/types/users';
import { isInArray } from 'utils/array';
import { useQuery } from '@apollo/client';
import dealPriorityConfiguration from './DealPriorityConfiguration.gqls';
import { FetchPolicies } from 'utils/types/common';
import { CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { updateDealPriority } from 'utils/api/dealPriority';
import { DealPriority } from './components/DealPriorityConfiguration.const';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { Loader } from 'components/shared/loader';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';

const DealPriorityConfiguration = () => {
  const { user } = useSelector(users);
  const isUserEligibleForEdit = isInArray([UserRole.SysAdmin, UserRole.Admin], user?.marketInfo[0]?.role);
  const { data } = useQuery(dealPriorityConfiguration.queries.getAllDealPriority, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const priority = [
    { id: 1, name: 'Priority 1', value: 10000 },
    { id: 2, name: 'Priority 2', value: 20000 },
    { id: 3, name: 'Priority 3', value: 30000 },
    { id: 4, name: 'Priority 4', value: 40000 },
    { id: 5, name: 'Priority 5', value: 50000 },
    { id: 6, name: 'Priority 6', value: 60000 },
  ];
  let initalPriority: React.SetStateAction<any[]> = [];
  const dealPriorityArray = data?.getDealPriority || [];
  const [items, setItems] = useState(initalPriority);
  const [mode, setMode] = useState(FormMode.View);
  const isDisabled = mode === FormMode.View;

  if (data && data.getDealPriority) {
    initalPriority = dealPriorityArray
      .slice()
      .sort((a: { priority: number }, b: { priority: number }) => a.priority - b.priority)
      .map((item: any) => ({
        ...item,
        name: CampaignTypeLabel[item.campaignType as CampaignType],
      }));
  }
  const getTaskPos = (id: number) => items.findIndex((task: { id: number }) => task.id === id);
  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }
    setItems((allitems: any) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);
      const newItems = arrayMove(allitems, originalPos, newPos);
      return newItems.map((item: any, index) => {
        return {
          ...item,
          priorityId: priority[index].id,
          priority: priority[index].value,
        };
      });
    });
  }
  function handleCancel() {
    setMode(FormMode.View);
    setItems(initalPriority);
  }
  function handleOnEdit() {
    setMode(FormMode.Edit);
  }
  async function handleSubmit(itemsToSubmit: DealPriority[]) {
    const transformedItems: DealPriority[] = itemsToSubmit.map(({ name, priorityId, __typename, ...rest }) => rest);
    try {
      setIsSubmitting(true);
      await updateDealPriority(transformedItems);
      setIsSubmitting(false);
      setMode(FormMode.View);
    } catch (e) {
      showToast(MessageType.Error, `Default Campaign priority not updated`);
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    if (data && data.getDealPriority) {
      setItems(initalPriority);
    }
  }, [data]);
  return (
    <>
      {isSubmitting && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <PageContainer>
        <StyledHeader data-automation-id="header">
          <TextPageTitle text={tabName.DealsPriorityConfiguration} />
          <Actions>
            {mode === FormMode.View && isUserEligibleForEdit && (
              <ButtonContained onClick={handleOnEdit}>Edit</ButtonContained>
            )}
            {mode === FormMode.Edit && (
              <>
                <ButtonContained onClick={() => handleSubmit(items)}>Save</ButtonContained>{' '}
                <ButtonText onClick={handleCancel}>Cancel</ButtonText>
              </>
            )}
          </Actions>
        </StyledHeader>
        {!items.length ? (
          <StyledLoader size={LoaderSize.Medium} />
        ) : (
          <StyledDnDItems data-automation-id={`drag-and-drop-box`}>
            <PriorityTitle priority={priority} />
            <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
              <DragAndDropList items={items} disabled={isDisabled} color={'yellow'} />
            </DndContext>
          </StyledDnDItems>
        )}
      </PageContainer>
    </>
  );
};

export default DealPriorityConfiguration;
