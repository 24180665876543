import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { closeModal } from 'app/slices/modals';
import { users } from 'app/slices/users';
import { store } from 'app/store';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import TextField from 'components/shared/textField/TextField';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Checkbox from 'components/shared/checkbox/Checkbox';
import ReactTooltip from 'react-tooltip';
import { capitalize } from 'utils/text';
import { FormMode } from 'utils/types';
import { useMutation } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { AuthType, UserNotificationRecord, UserNotificationType, UserRole, UserRoleRecord, User } from 'utils/types/users';
import { hideTooltip } from 'utils/tooltip';
import ToggleGroup from 'components/shared/toggleGroup/ToggleGroup';
import { StyledLabel } from 'components/shared/textField/TextField.style';
import { isInArray } from 'utils/array';
import {
  CheckboxWrapper,
  NotificationRegisteration,
  RoleSelectbox,
  StyledInfoTooltip,
  MarketSelectbox,
  StyledModal,
  StyledTooltip,
  UserManagementModalColumn,
  UserManagementModalCompany,
  UserManagementModalContainer,
  UserManagementModalFooter,
  UserManagementModalHorizontalColumn,
  UserManagementModalVerticalColumn,
  UserManagementModalNames,
  StyledIcon,
  Button
} from './UserManagementModal.style';
import { AuthTypes, Companies, UserManagementModalProps, Record } from './UserManagementModal.consts';
import usersGqls from '../../UserManagement.gqls';
import { NotificationActionType } from 'utils/types/notification';
import { ValidationMessages } from 'utils/types/common';
import { omit, omitBy } from 'lodash';

const UserManagementModal = ({ user, mode, allUsersData }: UserManagementModalProps) => {
  const currentLoggedInUser = useSelector(users).user;
  const [defultAddUserMarket, setDefultAddUserMarket] = useState([])
  const [userEmailEntered, setUserEmailEntered] = useState<User | null>(null);
  const [authenticationEnabled, setAuthenticationEnabled] = useState(false)
  function createMarketObjects(userMarkets: any[], marketInfo: any[]) {
    const marketObjects: { [key: string]: string[] } = {};

    userMarkets?.forEach((userMarket) => {
      const { role, marketName } = userMarket;

      if (role === "sysadmin") {
        return;
      }

      const authorizedMarkets = marketInfo
        .filter((info) => (info.role === UserRole.Admin || info.role === UserRole.SysAdmin) && info.isActive)
        .map((info) => info.marketName);

      if (authorizedMarkets.includes(marketName)) {
        if (!marketObjects[role]) {
          marketObjects[role] = [];
        }
        if (marketName && !marketObjects[role].includes(marketName.toLowerCase())) {
          marketObjects[role].push(marketName.toLowerCase());
        }
      }
    });

    const formattedMarketObjects = Object.keys(marketObjects).map((role) => ({
      marketName: marketObjects[role],
      role,
    }));

    return formattedMarketObjects;
  }

  const [modalMode, setModalMode] = useState(mode);
  const {
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: cloneDeep(omitBy(user, isNil)) as any,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'marketConfigration',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createUser] = useMutation(usersGqls.mutations.add);
  const [updateUser] = useMutation(usersGqls.mutations.update);
  const [deleteUser] = useMutation(usersGqls.mutations.delete);
  const [unlockUser] = useMutation(usersGqls.mutations.unlock);
  const [firstName, lastName, authType, role, marketConfigration] = useWatch({
    control,
    name: ['firstName', 'lastName', 'authType', 'role', 'marketConfigration'],
  });
  const [notifications, setNotifications] = useState(user?.marketInfo.find((marketInfo) => marketInfo.isDefault)?.notifications ?? []);
  const [market, setMarket] = useState([]);
  const [marketsValue, setMarketsValue] = useState([]);
  const [existingUser, setExistingUser] = useState(false);
  const [transformedData, setTransformedData] = useState([])
  const [emailErrorMessage, setEmailErrorMessage] = useState(false)
  const [userFound, setUserFound] = useState(false); 

  useEffect(() => {
    if (modalMode !== FormMode.Edit && modalMode !== FormMode.View) {
      append({ marketName: [], role: '' });
    }
    if (modalMode == FormMode.Edit || modalMode == FormMode.View) {
      const userMarket = createMarketObjects(user?.marketInfo, currentLoggedInUser?.marketInfo)
      setValue("marketConfigration", userMarket);
      setValue('authType', user?.authType)
      setNotifications(user?.marketInfo.find((marketInfo) => marketInfo.isDefault)?.notifications ?? [])
      setValue('firstName', user?.firstName)
      setValue('lastName', user?.lastName)
    }
    setTransformedData(currentLoggedInUser?.marketInfo.map(item => ({
      id: item.marketName.toLowerCase(),
      name: item.marketName
    })));
  }, []);

  useEffect(() => {
    if (modalMode === FormMode.New && defultAddUserMarket.length > 0) {
      setTransformedData(currentLoggedInUser?.marketInfo.map(item => ({
        id: item.marketName.toLowerCase(),
        name: item.marketName
      })));
      setValue('marketConfigration', defultAddUserMarket)
      setValue('company', userEmailEntered?.company)
      setValue('authType', userEmailEntered?.authType)
      setValue('firstName', userEmailEntered?.firstName)
      setValue('lastName', userEmailEntered?.lastName)
    }
  }, [defultAddUserMarket]);

  const onSelectMarket = (items: [], index: number) => {
    if (items != undefined) {
      const updatedMarket = [...market.slice(0, index), items, ...market.slice(index + 1)];
      setMarket(updatedMarket);
    }
  };

  const updateItemSelected = () => {
    const flattenedArray = market.flat();
    const uniqueMarkets = new Map();
    flattenedArray.forEach((market) => {
      uniqueMarkets.set(market.id, market);
    });
    const mergedArray = Array.from(uniqueMarkets.values());
    setMarketsValue(mergedArray);
  };

  useEffect(() => {
    updateItemSelected();
  }, [market]);

  const marketDropdownValues = (index: number) => {
    if (fields.length - 1 === index) {
      const lastMarket = marketConfigration.slice(0, -1);
      const marketNames = lastMarket.flatMap((item: { marketName: any; }) => item.marketName);
      const notPresentInMarketNames = transformedData.filter((item: { id: any; }) => !marketNames.includes(item.id));
      return notPresentInMarketNames
    } else {
      const allMarketNames = marketConfigration?.flatMap((item: { marketName: any; }) => item.marketName);
      const filteredData = transformedData.filter(item => !allMarketNames?.includes(item.id));
      return filteredData
    }
  }

  const mapMarketInfoToData = (formData: any) => {
    const marketInfo = formData.marketConfigration
    let mappedData: {
      marketId: any; role: any; isDefault: any; isActive: any; marketName: any; marketFullName: any, notifications: any[];
    }[] = [];
    marketInfo.forEach((info: { marketName: any[]; role: any; }) => {
      info.marketName.forEach((market: string) => {
        const marketDetails = currentLoggedInUser.marketInfo.find((info) => info.marketName === market.toUpperCase())
        mappedData.push({
          marketId: marketDetails.marketId,
          role: info.role.charAt(0).toUpperCase() + info.role.slice(1),
          isDefault: false,
          isActive: true,
          marketName: market.toUpperCase(),
          marketFullName: marketDetails?.marketFullName,
          notifications: formData.notifications || [],
        });

      });
    });
    return mappedData;
  };

const onSave = async (formData: any) => {
  setIsSubmitting(true);
  if (modalMode === FormMode.Edit || existingUser) {
    let marketInfo = getMarketInfo({ ...formData }, user ? user : userEmailEntered)
    try {
      await updateUser({
        variables: {
          data: {
            id: formData.id ? formData.id : userEmailEntered?.id,            
            company: formData.company,
            firstName: formData.authType === AuthType.VCE ? firstName : undefined,
            lastName: formData.authType === AuthType.VCE ? lastName : undefined,
            marketInfo: marketInfo,
          },
        },
       refetchQueries: ['Users'],
      });
      showToast(MessageType.Success, `User updated successfully`);
      store.dispatch(closeModal());
    } catch (e) {
      showToast(MessageType.Error, `Failed to update user`);
    }
  } else if (modalMode === FormMode.New) {
    let marketInfo = mapMarketInfoToData(formData)
    try {
      await createUser({
        variables: {
          data: {
            email: formData.email,
            marketInfo: marketInfo,
            company: formData.company,
            authType: formData.authType.toUpperCase(),
            firstName: formData.authType === AuthType.VCE ? firstName : undefined,
            lastName: formData.authType === AuthType.VCE ? lastName : undefined,
          },
        },
        refetchQueries: ['Users'],
      });
        showToast(MessageType.Success, `User added successfully`);
        store.dispatch(closeModal());
      } catch (e) {
        if (e.message.includes('already exists')) {
          showToast(MessageType.Error, `Failed to add user - ${formData.email} already exists`);
        } else {
          showToast(MessageType.Error, `Failed to add user`);
        }
      }
    }
    setIsSubmitting(false);
  };

  const getMarketInfo = (formData: any, user: any) => {
    //  let role = formData.role === UserRole.SysAdmin ? 'SysAdmin' : capitalize(formData.role);
    let notification = [UserRole.Trainee, UserRole.Viewer].includes(formData.role)
      ? formData?.notifications
        ? formData?.notifications.filter(
          (ele: NotificationActionType) => ele !== NotificationActionType.CampaignApprovalRequired,
        )
        : []
      : formData?.notifications ?? [];
    let selectedMarketName = currentLoggedInUser.marketInfo.find(info => info.isDefault).marketName;
    let userDetail = JSON.stringify(user);
    const userDetail1 = JSON.parse(userDetail);
    let updateMarketInfo = userDetail1.marketInfo;

    if (currentLoggedInUser.id === Number(user.id)) {

      const updatedMarketInfo = updateMarketInfo.map((market: any) => {
        if ((market as any).__typename) {
          delete (market as any).__typename;
        }
        if (updateMarketInfo.length === 1) {
          return { ...market, notifications: notification, role: capitalize(formData.marketConfigration[0].role) }
        }
        else {
          if (market.marketName === selectedMarketName) {
            return { ...market, notifications: notification, }
          }
        }

        return { ...market };
      })
      return updatedMarketInfo;
    }

    else {
      let marketToDelete: String;

      let currentLoggedUserMarketList = currentLoggedInUser.marketInfo.filter(a => a.role === UserRole.Admin || a.role === UserRole.SysAdmin).map(x => x.marketName);

      const updatedMarketInfo1 = updateMarketInfo.map((market: any) => {
        let updatedMarket;
        if ((market as any).__typename) {
          delete (market as any).__typename;
        }
        let marketToUpdate;
       marketConfigration.forEach((info: { marketName: any[]; role: any; }) => {
        let matchedMarket = info.marketName.find(a=>a.toString().toUpperCase() === market.marketName);
       
        if(matchedMarket !== undefined){
          marketToUpdate = info.role;
        }
      });
        if (marketToUpdate !== undefined) {
          market = { ...market, role: capitalize(marketToUpdate) }
          if (market.isDefault) {
            market = { ...market, role: capitalize(marketToUpdate), notifications: notification }
          }
        }
        if (marketToUpdate === undefined && currentLoggedUserMarketList.includes(market.marketName)) {
          // return {...market, isActive:false}
          market = { ...market, role: capitalize(market.role), isActive: false };
          if (market.isDefault === true) {
            market = { ...market, isActive: false, isDefault: false };
          }
        }

        return { ...market }
      })
      getAddedMarket(formData.marketConfigration, notification, updatedMarketInfo1, user);
      return updatedMarketInfo1;
    }

  };

  const getAddedMarket = (marketConfigration: any, notification: any, updatedMarketInfo: any, user: any) => {
    let userMarketList = user.marketInfo.map((a: { marketName: any; }) => a.marketName);

    let currentLoggedUserMarketList = currentLoggedInUser.marketInfo.filter(a => a.role === UserRole.Admin || a.role === UserRole.SysAdmin).map(x => x.marketName);
    console.log(currentLoggedUserMarketList)
    marketConfigration.map((config: any) => {
      config.marketName.map((name: any) => {
        name = name.toString().toUpperCase();
        if (currentLoggedUserMarketList.includes(name) && !userMarketList.includes(name)) {
          let marketToAdd = { ...currentLoggedInUser.marketInfo.find(market => market.marketName === name) };
          marketToAdd.isActive = true;
          marketToAdd.isDefault = false;
          marketToAdd.userId = Number(user.id);
          marketToAdd.role = config.role.charAt(0).toUpperCase() + config.role.slice(1)
          marketToAdd.marketFullName = currentLoggedInUser.marketInfo.find(info => info.marketName === name).marketFullName;
          marketToAdd.notifications = notification;
          updatedMarketInfo.push(marketToAdd);
        }
      })
    });
    return updatedMarketInfo;
  };
  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.New:
        return 'Add User';
      case FormMode.Edit:
        return `Edit User ${user.email}`;
      case FormMode.View:
        return `View User ${user.email}`;
      default:
        return '';
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

  const handleDeleteClick = async () => {
    if (!isDeleteButtonClicked) {
      setIsDeleteButtonClicked(true);
      const userAciveMarkets = user?.marketInfo?.filter((info) => info.isActive);
      const adminActiveMarkets = currentLoggedInUser?.marketInfo?.filter((info) => info.isActive);
      const overlappingMarkets = userAciveMarkets?.filter((market) =>
        adminActiveMarkets?.some((adminMarket) => adminMarket.marketId === market.marketId),
      );
      await deleteUser({
        variables: {
          data: {
            id: Number(user.id),
            marketId:
              overlappingMarkets?.length === 1 ? overlappingMarkets[0].marketId : adminActiveMarkets?.[0]?.marketId,
          },
        },
        refetchQueries: ['Users'],
      });
      hideTooltip('#delete-tooltip');
      ReactTooltip.hide();
    }
  };

  const renderIcon = (index: number) => {
    const isTransformedDataSingle = transformedData.length === 1;
    if (isTransformedDataSingle) {
      return null;
    }
    if (index === 0) {
      if (marketsValue?.length < transformedData.length) {
        if (fields.length > 1) {
          return (
            <ButtonText
              onClick={() => {
                remove(index);
                const restExceptIndex = [...market.slice(0, index), ...market.slice(index + 1)];
                setMarket(restExceptIndex);
                updateItemSelected()
              }}
              disabled={false}
            >
              <StyledIcon name="minusVector" disabled={false} />
            </ButtonText>
          );
        } else {
          return (
            <ButtonText
              onClick={() => {
                if (marketsValue.length != 0) {
                  append(index);
                }
              }}
              disabled={false}
            >
              <StyledIcon name="plusVector" disabled={false} />
            </ButtonText>
          );
        }
      } else if (fields.length > 1) {
        return (
          <ButtonText
            onClick={() => {
              remove(index);
              const restExceptIndex = [...market.slice(0, index), ...market.slice(index + 1)];
              setMarket(restExceptIndex);
              updateItemSelected();

            }}
            disabled={false}
          >
            <StyledIcon name="minusVector" disabled={false} />
          </ButtonText>
        );
      } else {
        return null;
      }
    } else {
      if (marketsValue?.length < transformedData.length) {
        if (fields.length - 1 > index) {
          return (
            <ButtonText
              onClick={() => {
                remove(index);
                const restExceptIndex = [...market.slice(0, index), ...market.slice(index + 1)];
                setMarket(restExceptIndex);
                updateItemSelected();
              }
              }
              disabled={false}
            >
              <StyledIcon name="minusVector" disabled={false} />
            </ButtonText>
          );
        } else {
          return (
            <ButtonText
              onClick={() => {
                if (marketConfigration[marketConfigration.length - 1]?.marketName === undefined) {
                  null
                } else {
                  append(index);
                }
              }}
              disabled={false}
            >
              <StyledIcon name="plusVector" disabled={false} />
            </ButtonText>
          );
        }
      } else {
        return (
          <ButtonText
            onClick={() => {
              remove(index);
              const restExceptIndex = [...market.slice(0, index), ...market.slice(index + 1)];
              setMarket(restExceptIndex);
              updateItemSelected();

            }}
            disabled={false}
          >
            <StyledIcon name="minusVector" disabled={false} />
          </ButtonText>
        );
      }
    }
  };

  const getIsNotificationDisabled = (key: UserNotificationType) =>
    key === UserNotificationType.CampaignRequireApproval && isInArray([UserRole.Trainee, UserRole.Viewer], role);

  const handleProductOrLocationUpdate = (userNotifications: string[]) => {
    const keysToDelete = [];
    if (!userNotifications.includes(UserNotificationType.ProductUpdate) && userNotifications.includes('noProducts')) {
      keysToDelete.push('noProducts');
    }
    if (!userNotifications.includes(UserNotificationType.LocationUpdate) && userNotifications.includes('noLocations')) {
      keysToDelete.push('noLocations');
    }
    return keysToDelete;
  };

  const onChangeFocusOfEmail = () => {
    if (control._formValues.email) {
      const emailToFind = control._formValues.email;
      let marketsInCurrentLoggedInUserNotInUser;
      const user = allUsersData.find(user => user.email === emailToFind);
      if (user) {
        setUserEmailEntered(user)
        setExistingUser(true)
        setUserFound(true)
        const userMarketNames = user.marketInfo.map(info => info.marketName);
        const currentLoggedInUserMarketNames = currentLoggedInUser.marketInfo.map(info => info.marketName);
        marketsInCurrentLoggedInUserNotInUser = currentLoggedInUserMarketNames.filter(name => !userMarketNames.includes(name));
        if (marketsInCurrentLoggedInUserNotInUser.length > 0) {
          setDefultAddUserMarket(createMarketObjects(user?.marketInfo, currentLoggedInUser?.marketInfo))
        }
        let transformedData11 = marketsInCurrentLoggedInUserNotInUser?.map(item => ({
          id: item.toLowerCase(),
          name: item
        }));
        setAuthenticationEnabled(true)
        if (transformedData11.length > 0) {
          setTransformedData(transformedData11)
          setEmailErrorMessage(false)
        } else {
          setEmailErrorMessage(true)
        }
      }
      else {
        let transformedData = currentLoggedInUser?.marketInfo?.map(item => ({
          id: item.marketName.toLowerCase(),
          name: item.marketName
        }));
        setExistingUser(false)
        setAuthenticationEnabled(false)
        setTransformedData(transformedData)
        setEmailErrorMessage(false)
        if(userFound){
          reset()
          append({ marketName: [], role: '' });
          setUserFound(false)
        }
      }
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  useEffect(() => {
    if (modalMode !== FormMode.View && modalMode !== FormMode.Edit && authType === AuthType.VCE) {
      setValue('company', 'Other');
    }
  }, [authType]);

  const checkDeleteEnabled = (currentUser: any, user: any) => {
    const currentUserAdminMarkets = currentUser.marketInfo.filter((market: any) => market.role === UserRole.Admin || market.role === UserRole.SysAdmin);
    const userMarkets = user.marketInfo;

    if (currentUserAdminMarkets.length === 0 || currentLoggedInUser.id === Number(user.id)) {
      return false;
    }
    const currentUserAdminMarketIds = currentUserAdminMarkets.map((market: any) => market.marketId);
    const userMarketIds = userMarkets.map((market: any) => market.marketId);

    return userMarketIds.every((marketId: any) => currentUserAdminMarketIds.includes(marketId));
  };


  return (
    <StyledModal title={getModalTitle()}>
      <UserManagementModalContainer>
        <UserManagementModalColumn>
          <header>
            <StyledLabel>Authentication*</StyledLabel>
            <ToggleGroup
              itemList={AuthTypes}
              control={control}
              name="authType"
              defaultValue="gas"
              disabled={modalMode !== FormMode.New || authenticationEnabled}
            />
          </header>
          <UserManagementModalCompany>
            <span>Company*</span>
            <RadioGroup
              control={control}
              name="company"
              radioList={Companies}
              defaultValue={Companies[0].value}
              disabled={modalMode === FormMode.View}
            />
          </UserManagementModalCompany>
          <TextField
            register={register}
            errors={emailErrorMessage ? 'User already exists in the markets' : errors}
            name="email"
            label="Email"
            placeholder="Enter"
            labelIsHorizontal
            type="email"
            value={user?.email}
            validation={{
              required: true,
              pattern: {
                value:
                  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid Email',
              },
            }}
            disabled={modalMode !== FormMode.New}
            onBlur={onChangeFocusOfEmail}
          />
          {authType === AuthType.VCE ? (
            <UserManagementModalNames>
              <TextField
                register={register}
                errors={errors}
                name="firstName"
                label="First Name"
                labelIsHorizontal
                disabled={!isInArray([FormMode.New, FormMode.Edit], modalMode)}
                validation={{
                  required: ValidationMessages.RequiredField,
                }}
              />
              <TextField
                register={register}
                errors={errors}
                name="lastName"
                label="Last Name"
                labelIsHorizontal
                disabled={!isInArray([FormMode.New, FormMode.Edit], modalMode)}
                validation={{
                  required: ValidationMessages.RequiredField,
                }}
              />
            </UserManagementModalNames>
          ) : null}
          <NotificationRegisteration>
            <span>Email Notifications</span>
            {Object.entries(UserNotificationRecord).map(([key, val]: [string, string]) => {
              return (
                <Controller
                  control={control}
                  name="notifications"
                  render={({ field: { onChange } }) => (
                    <CheckboxWrapper>
                      <Checkbox
                        disabled={modalMode === FormMode.View || getIsNotificationDisabled(key as UserNotificationType)}
                        label={val === 'Campaign Error Notification' ? 'Campaign Notification' : val}
                        onClick={() => {
                          const newNotifications = [...notifications];
                          if (notifications?.includes(key)) {
                            newNotifications.splice(notifications.indexOf(key), 1);
                            const keysToRemove = handleProductOrLocationUpdate(newNotifications);
                            keysToRemove.forEach((k) => newNotifications.splice(newNotifications.indexOf(k), 1));
                          } else {
                            newNotifications.push(key);
                          }
                          setNotifications(newNotifications);
                          onChange(newNotifications);
                        }}
                        checked={notifications?.includes(key) && !getIsNotificationDisabled(key as UserNotificationType)}
                      />
                      {val === 'Campaign Error Notification' && (
                        <StyledInfoTooltip
                          id="uptoTooltip"
                          className="userManagementCampaignNotification"
                          content="User will receive bulk campaign notification and campaign error notification if enabled."
                        />
                      )}
                    </CheckboxWrapper>
                  )}
                />
              );
            })}
          </NotificationRegisteration>
        </UserManagementModalColumn>
        <UserManagementModalHorizontalColumn>
          {(fields).map((line, index) => (
            <UserManagementModalVerticalColumn key={line?.id}>
              {!(currentLoggedInUser.id === Number(user?.id) && currentLoggedInUser.marketInfo?.length === 1 && modalMode === FormMode.Edit) && (
                <MarketSelectbox
                  items={transformedData}
                  multiple={true}
                  control={control}
                  name={`marketConfigration.${index}.marketName`}
                  errors={errors}
                  label="Market"
                  labelIsHorizontal
                  validation={{ required: true }}
                  onChange={() => {
                    trigger(`marketConfigration.${index}.marketName`);
                  }}
                  disabled={modalMode === FormMode.View || (currentLoggedInUser.id === Number(user?.id) && currentLoggedInUser.marketInfo?.length > 1)}
                  maxItems={2}
                  selectWidth={160}
                  onSelectMarket={onSelectMarket}
                  index={index}
                  reset={false}
                  marketRecord={fields.length === 1 ? transformedData : marketDropdownValues(index)}
                  marketConfigration={marketConfigration && marketConfigration[index]?.marketName}
                />
              )}
              {user?.marketInfo?.find(market => market?.marketId === currentLoggedInUser?.marketInfo?.find(market => market?.isDefault)?.marketId)?.role!== UserRole.SysAdmin && (
                <RoleSelectbox
                  items={
                    Object.entries(UserRoleRecord)
                      .filter(([key]) => key !== UserRole.SysAdmin)
                      .map(([key, value]) => {
                        return {
                          id: key,
                          name: value,
                        };
                      }) as any[]
                  }
                  control={control}
                  name={`marketConfigration.${index}.role`}
                  errors={errors}
                  label="Role"
                  labelIsHorizontal
                  validation={{ required: true }}
                  onChange={() => {
                    trigger(`marketConfigration.${index}.role`);
                  }}
                  disabled={modalMode === FormMode.View || (currentLoggedInUser.id === Number(user?.id) && currentLoggedInUser.marketInfo?.length > 1 && modalMode === FormMode.Edit)}
                  selectWidth={100}
                  index={index}
                  marketConfigration={marketConfigration && marketConfigration[index]?.role}
                />
              )}
              <Button>{modalMode !== FormMode.View && currentLoggedInUser.id !== Number(user?.id) && renderIcon(index)}

              </Button>
            </UserManagementModalVerticalColumn>
          ))}
        </UserManagementModalHorizontalColumn>
        <UserManagementModalFooter>
          <ButtonText
            data-for="cancel-tooltip"
            {...(isDirty ? { 'data-tip': true, onClick: () => null } : { onClick: () => store.dispatch(closeModal()) })}
          >
            Cancel
          </ButtonText>
          {modalMode === FormMode.View ? (
            <>
              <StyledTooltip
                id="delete-tooltip"
                content={`Are you sure you want to delete ${user.email}?`}
                approveMsg="Yes, Delete"
                onDisapproveClick={() => {
                  hideTooltip('#delete-tooltip');
                }}
                onApproveClick={() => {
                  handleDeleteClick();
                  showToast(MessageType.Success, `User deleted successfully`);
                  store.dispatch(closeModal());
                }}
              />
                <ButtonOutlined data-tip data-for="delete-tooltip" onClick={() => null}  disabled={!checkDeleteEnabled(currentLoggedInUser, user)}>
                  Delete
                </ButtonOutlined>
              {user?.isLocked === true ? (
                <ButtonOutlined
                  onClick={async () => {
                    try {
                      await unlockUser({
                        variables: {
                          id: user.id,
                        },
                        refetchQueries: ['Users'],
                      });
                      showToast(MessageType.Success, `User unlocked successfully`);
                      store.dispatch(closeModal());
                    } catch (error) {
                      showToast(MessageType.Error, `User unlock failed, Please try again`);
                      // Handle error
                    }
                  }}
                >
                  Unlock
                </ButtonOutlined>
              ) : (
                <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
              )}
            </>
          ) : (
            <>
              <ButtonContained onClick={handleSubmit(onSave)} disabled={!isValid || !isDirty || isSubmitting || emailErrorMessage}>
                Save
              </ButtonContained>
              <StyledTooltip
                id="cancel-tooltip"
                content="Are you sure you want to cancel?"
                onDisapproveClick={() => {
                  hideTooltip('#cancel-tooltip');
                }}
                onApproveClick={() => store.dispatch(closeModal())}
              />
            </>
          )}
        </UserManagementModalFooter>
      </UserManagementModalContainer>
    </StyledModal>
  );
};

export default UserManagementModal;