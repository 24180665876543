import useOnClickOutside from 'hooks/use-onclick-outside';
import React, { useRef, useState } from 'react';
import { getAcronym } from 'utils/text';
import { UserRole, UserRoleRecord } from 'utils/types/users';
import { UserMenuProps } from './UserMenu.consts';
import { Avatar, UserMenuDropdown, Welcome, StyledLogoutButton, StyledUserRole } from './UserMenu.style';

const UserMenu = ({ user }: UserMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));
  const userRole = user.marketInfo.find(marketInfo => marketInfo.isDefault).role;

  return (
    <Welcome ref={ref} data-automation-id="user-container">
      <Avatar onClick={() => setIsOpen(!isOpen)}>{getAcronym(user)}</Avatar>
      {isOpen && (
        <UserMenuDropdown data-automation-id="user-menu">
          <header>
            <Avatar>{getAcronym(user)}</Avatar>
            <h5>{`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}</h5>
          </header>
          <section>
            <p>
              <h6>Email</h6>
              <div>{user.email}</div>
            </p>
            <p>
              <h6>Company</h6>
              <div>{user.company}</div>
            </p>
            <p>
              <h6>Role</h6>
              <StyledUserRole>{UserRoleRecord[userRole as UserRole]}</StyledUserRole>
            </p>
          </section>
          <footer>
            <StyledLogoutButton
              onClick={() => {
                document.location.href = '/logout';
              }}
            >
              Logout
            </StyledLogoutButton>
          </footer>
        </UserMenuDropdown>
      )}
    </Welcome>
  );
};

export default UserMenu;
