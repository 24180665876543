export interface User {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  authType: AuthType;
  email: string;
  company?: string;
  active?: boolean;
  isLocked?: boolean;
  showDefaultMarketModel: boolean;
  marketInfo: UserMarket[];
}

export interface allUsersData {
  [x: string]: any;
  __typename: "User";
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  company: string;
  role: string;
  notifications: any[];
  authType: string;
  isLocked: boolean;
  marketInfo: UserMarket[];
}

export interface Market {
  marketId: string;
  displayName: string;
  active: boolean;
}

export interface UserMarket {
  userId: number
  marketId: number;
  role?: UserRole;
  isDefault: boolean;
  isActive: boolean;
  notifications?: string[];
  marketName: String;
  marketFullName: String;
}

export enum UserRole {
  SysAdmin = 'sysadmin',
  Admin = 'admin',
  Creator = 'creator',
  Trainee = 'trainee',
  Viewer = 'viewer',
}

export const UserRoleRecord: Record<UserRole, string> = {
  [UserRole.SysAdmin]: 'System Admin',
  [UserRole.Admin]: 'Admin',
  [UserRole.Creator]: 'Creator',
  [UserRole.Trainee]: 'Trainee',
  [UserRole.Viewer]: 'Viewer',
};

export enum UserNotificationType {
  NoRedemptions = 'noRedemptions',
  CampaignErrors = 'campaignErrors',
  CampaignRequireApproval = 'campaignApprovalRequired',
  ProductUpdate = 'productUpdate',
  LocationUpdate = 'locationUpdate',
}

export const UserNotificationRecord: Record<UserNotificationType, string> = {
  [UserNotificationType.NoRedemptions]: 'No Redemptions Notification',
  [UserNotificationType.CampaignErrors]: 'Campaign Error Notification',
  [UserNotificationType.CampaignRequireApproval]: 'Campaign Requires Approval Notification',
  [UserNotificationType.ProductUpdate]: 'Product Update Notification',
  [UserNotificationType.LocationUpdate]: 'Location Update Notification',
};

export enum AuthType {
  GAS = 'gas',
  VCE = 'vce',
}

export interface UserNameNotification {
  firstName: string;
  lastName: string;
}
