import React, { useEffect, useState } from 'react';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import TextField from 'components/shared/textField/TextField';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FormState } from '../../Login.consts';
import { Error as ErrorComponent } from '../../Login.style';
import { LoginFormProps } from './LoginForm.consts';
import { PasswordSection, LoginContainerBody } from './LoginForm.style';
import useDynamicSVGImport from 'hooks/use-dynamic-svg-import';
import { defaultTheme } from 'styles/themes/defaultTheme';

export const LoginForm = ({ state, dispatch }: LoginFormProps) => {
  const { register, handleSubmit, getValues, watch } = useForm({});
  const [loginProccessing, setLoginProccessing] = useState(false);
  const location = useLocation();
  const [autofill, setAutofill] = useState(false); 
  useDynamicSVGImport("eyePasswordHide");

  const onSubmit = async (formData: any) => {
    formData = btoa(JSON.stringify(formData));
    setLoginProccessing(true);

    let res = await fetch(`/login${location.search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({eFormData: formData}),
      credentials: 'include'
    });
    if (res.ok) {
      window.location.href = res.url;
    } else {
      res = await res.json();
      dispatch({ type: 'SUBMIT_LOGIN', payload: res });
    }
    setLoginProccessing(false);
  };

  const enteredUsername = watch('username');
  const enteredPassword = watch('password');
  const handleKeyPress = async (e: KeyboardEvent) => {
    const username = getValues('username');
    const password = getValues('password');
    if (e.key === 'Enter') {
      if (username && password) {
        dispatch({ type: 'SET_ERROR', payload: {error: null} });
        await onSubmit({ ['username']: username, ['password']: password  });
      }
      else {
        dispatch({ type: 'SET_ERROR', payload: { error: 'Username & password are required to log in'} });
      }
    }
  };

  useEffect(() => {
    setAutofill(false);
    setTimeout(() => {
      const usernameElement = document.getElementById('loginUsername');
      const passwordElement = document.getElementById('loginPassword');
      let usernameBackground = defaultTheme.colors.global.backgroundRGBA;
      let passwordBackground = defaultTheme.colors.global.backgroundRGBA;
      if (usernameElement && passwordElement) {
        usernameBackground = window.getComputedStyle(usernameElement).backgroundColor;
        passwordBackground = window.getComputedStyle(passwordElement).backgroundColor;
      }
      if (usernameBackground !== defaultTheme.colors.global.backgroundRGBA && passwordBackground !== defaultTheme.colors.global.backgroundRGBA) {
        setAutofill(true);
      }
      else {
        setAutofill(false);
      }
    }, 2000)
    window.addEventListener('keydown', handleKeyPress);
    return () =>
      window.removeEventListener('keydown', handleKeyPress);
  }, [enteredUsername, enteredPassword])

  const checkDisabled = () => {
    if (autofill) {
      return false;
    }
    else {
      if (!((enteredUsername && enteredUsername.length > 0) && (enteredPassword && enteredPassword.length > 0) ) || loginProccessing) {
        return true;
      }
    }
  }

  return (
    <LoginContainerBody>
      <p>Welcome to VCE! Please login to continue</p>
      <ButtonContained
        disabled={loginProccessing}
        onClick={() => {
          setLoginProccessing(true);
          window.location.href = `/initiateSSO${location.search ?? ''}`;
        }}
      >
        Login with MCD SSO
      </ButtonContained>
      <p>or</p>
      <TextField placeholder="Email" register={register} name="username"  id='loginUsername' />
      <PasswordSection>
        <TextField placeholder="Password" type="password" register={register} name="password" id='loginPassword'/>
        <ButtonText onClick={() => dispatch({ type: 'SET_FORM', payload: FormState.ForgotPassword })}>
          Forgot Password
        </ButtonText>
      </PasswordSection>
      <ButtonOutlined onClick={handleSubmit(onSubmit)} disabled={checkDisabled()}  >
        Login
      </ButtonOutlined>
      <ErrorComponent>{state.error && state.error}</ErrorComponent>
    </LoginContainerBody>
  );
};