import { gql } from '@apollo/client';

const usersGqls = {
  queries: {
    getAll: gql`
      query Users($data: GetAllQueryDto) {
        getUsers(data: $data) {
          items {
            ... on User {
              id
              email
              firstName
              lastName
              fullName
              company
              authType
              isLocked
              marketInfo {
                marketId
                userId
                isDefault
                isActive
                role
                marketName
                marketFullName
                notifications
              }
            }
          }
          total
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateUser($data: CreateUserDto!) {
        createUser(data: $data) {
          id
          email
        }
      }
    `,
    update: gql`
      mutation UpdateUserMutation($data: UpdateUserDto!) {
        updateUser(data: $data) {
          email
          marketInfo {
            marketId
            role
          }
        }
      }
    `,
    switchMarket: gql`
      mutation SwitchMarketMutation($data: UpdateUserDto!) {
        switchMarket(data: $data) {
          email
          marketInfo {
            marketId
            role
          }
        }
      }
    `,
    delete: gql`
      mutation DeleteUser($data: DeleteUserDto!) {
        deleteUser(data: $data) {
          id
          active
        }
      }
    `,
    unlock: gql`
      mutation UnlockUser($id: BigInt!) {
        unlockUser(id: $id) {
          id
        }
      }
    `,
    forgotPassword: gql`
      mutation ForgotPassword($data: ForgotPasswordDto!) {
        forgotPassword(data: $data)
      }
    `,
  },
};

export default usersGqls;
